import { format, parse } from 'date-fns';
import { isEqual } from 'lodash';
import Hoek from '../../../utils/hoek';

const flattenConfig = (schema) => {
	const properties = {};
	const ui = {};
	schema.steps.map((step) => {
		Object.assign(properties, step.schema.properties);
		Object.assign(ui, step.ui);
	});

	return {
		properties,
		ui,
	};
};

const unifyGuestNumberFormat = (numberOfGuests: string) =>
	numberOfGuests.replace(/-/g, '–').replace(/ – /g, '–');

const transformAndClean = (source, mappings) => {
	const transformed = Hoek.transform(source, mappings);
	// remove undefined properties
	const cleaned = Hoek.merge({}, transformed, false, true);
	return cleaned;
};

const trimProperties = (member, output) => {
	const trimValue = (input, key) => {
		if (input[key] && input[key].trim().length > 0) {
			return input[key].trim();
		}
	};

	[
		{
			inputKey: 'first_name',
			outputKey: 'firstName',
		},
		{
			inputKey: 'last_name',
			outputKey: 'lastName',
		},
		{
			inputKey: 'email',
			outputKey: 'emailAddress',
		},
	].forEach((item) => {
		const value = trimValue(member, item.inputKey);
		if (value) {
			output[item.outputKey] = value;
		}
	});
};

const _getVenue = (booking) => {
	return (
		booking &&
		booking.address_components &&
		(booking.address_components.local_vendor ||
			booking.address_components.google_place) &&
		booking
	);
};

const _getVenuePicker = (venue) => {
	const mapStorefront = (venue) => ({
		receptionVenue: {
			storefront: transformAndClean(venue, {
				id: 'vendor_profile_id',
				name: 'vendor_name',
				city: 'address_components.local_vendor.city',
				state: 'address_components.local_vendor.state',
			}),
			address_components: venue.address_components,
		},
	});

	const mapGooglePlace = (venue) => ({
		receptionVenue: {
			googlePlace: transformAndClean(venue, {
				id: 'address_components.google_place.place_id',
				name: 'vendor_name',
				city: 'address_components.google_place.locality',
				state: 'address_components.google_place.administrative_area_level_1',
			}),
			address_components: venue.address_components,
		},
	});

	return venue.address_components.local_vendor
		? mapStorefront(venue)
		: mapGooglePlace(venue);
};
// convert member data into form data
const loadMember = ({ member, wedding, booking, guest_count }) => {
	const output = {};
	if (!member) {
		return output;
	}

	// properties to trim
	trimProperties(member, output);

	// see if booking has venue
	const venue = _getVenue(booking);
	// get wedding location from member
	const address =
		member.addresses &&
		member.addresses.find((value) => value.address_type === 'wedding');

	// map venue form booking
	if (venue) {
		output.venue_picker = _getVenuePicker(venue);
	}
	// map location from member
	else if (address && address.city && address.state) {
		output.venue_picker = {
			receptionVenue: {
				location: {
					city: address.city,
					state: address.state,
				},
			},
		};
	}
	// map from wedding.location
	else if (
		wedding &&
		wedding.market &&
		wedding.market.city &&
		wedding.market.state
	) {
		output.venue_picker = {
			receptionVenue: {
				location: {
					city: wedding.market.city,
					state: wedding.market.state,
				},
			},
		};
	}

	if (member.wedding_date) {
		output.xo_date_picker = {
			weddingDate: format(
				parse(member.wedding_date, 'yyyy-MM-dd', new Date()),
				'yyyy-MM-dd',
			),
		};
	}

	if (member.number_of_guests && guest_count) {
		const selected = guest_count.values.find(
			(item) =>
				unifyGuestNumberFormat(item.display) ===
				unifyGuestNumberFormat(member.number_of_guests),
		);
		if (selected) {
			output.guest_count = selected;
		}
	}

	return output;
};

const localStorageCommonProps = () => {
	return [
		'firstName',
		'lastName',
		'emailAddress',
		'xo_date_picker',
		'guest_count',
		'phoneNumber',
		'venue_picker',
	];
};

const _selectProperties = (ui, elementKeys) => {
	const elements = [];

	Object.keys(ui).forEach((uiKey) => {
		const property = ui[uiKey];
		const match = elementKeys.find(
			(element) => property['ui:field'] && property['ui:field'] === element,
		);
		if (match) {
			elements.push(uiKey);
		}
	});

	return elements;
};

const singleSelectProperties = (ui) =>
	_selectProperties(ui, ['xo_single_select_list', 'xo_drop_down']);

const multiSelectProperties = (ui) =>
	_selectProperties(ui, ['xo_multi_select_list']);

// load form data from local storage
const loadLocalStorage = (category, properties, localStorage, ui) => {
	let data = {};

	// get common props
	const commonProps = {};
	localStorageCommonProps().forEach((key) => {
		if (key in localStorage) {
			commonProps[key] = localStorage[key];
		}
	});

	// get category props
	const categoryProps =
		(localStorage && category in localStorage && localStorage[category]) || {};

	data = { ...commonProps, ...categoryProps };

	// matches value against schema
	const findByValue = (input, config, key) => {
		if (input[key] && config[key] && config[key].values) {
			return (
				config[key] &&
				config[key].values.find((item) => isEqual(item.value, input[key].value))
			);
		}
	};

	const findByValues = (input, config, key) => {
		if (input[key] && config[key] && config[key].values) {
			return config[key].values.filter((configValue) =>
				input[key].find((inputValue) => isEqual(configValue, inputValue)),
			);
		}
		return [];
	};

	// single selects
	singleSelectProperties(ui).forEach((key) => {
		const match = findByValue(data, properties, key);
		if (match) {
			data[key] = match;
		} else {
			delete data[key];
		}
	});

	// multi selects
	multiSelectProperties(ui).forEach((key) => {
		const matches = findByValues(data, properties, key);
		if (matches.length) {
			data[key] = matches;
		} else {
			delete data[key];
		}
	});

	// special cases
	if (data.end_time_picker && properties.end_time_picker) {
		if (data.end_time_picker.has_selection) {
			data.end_time_picker.endTime = findByValue(
				data.end_time_picker,
				properties.end_time_picker.properties,
				'endTime',
			);
			data.end_time_picker.has_selection = !!data.end_time_picker.endTime;
		}

		if (!data.end_time_picker.has_selection) {
			// add placeholder value
			data.end_time_picker.endTime = { value: '' };
		}
	}

	return data;
};

export {
	flattenConfig,
	unifyGuestNumberFormat,
	loadMember,
	loadLocalStorage,
	localStorageCommonProps,
	multiSelectProperties,
	singleSelectProperties,
};
