export const spotlightExperimentMarkets = [
	{ categoryCode: 'BAR', marketCode: '006' },
	{ categoryCode: 'BEA', marketCode: '019' },
	{ categoryCode: 'BEA', marketCode: '191' },
	{ categoryCode: 'BEA', marketCode: '054' },
	{ categoryCode: 'BEA', marketCode: '020' },
	{ categoryCode: 'BEA', marketCode: '044' },
	{ categoryCode: 'BEA', marketCode: '186' },
	{ categoryCode: 'BEA', marketCode: '029' },
	{ categoryCode: 'BEA', marketCode: '073' },
	{ categoryCode: 'BEA', marketCode: '053' },
	{ categoryCode: 'BEA', marketCode: '177' },
	{ categoryCode: 'BEA', marketCode: '041' },
	{ categoryCode: 'BEA', marketCode: '017' },
	{ categoryCode: 'BWP', marketCode: '019' },
	{ categoryCode: 'BWP', marketCode: '061' },
	{ categoryCode: 'BWP', marketCode: '006' },
	{ categoryCode: 'BWP', marketCode: '190' },
	{ categoryCode: 'BWP', marketCode: '053' },
	{ categoryCode: 'BWP', marketCode: '209' },
	{ categoryCode: 'CAT', marketCode: '006' },
	{ categoryCode: 'CAT', marketCode: '004' },
	{ categoryCode: 'DJS', marketCode: '191' },
	{ categoryCode: 'DJS', marketCode: '030' },
	{ categoryCode: 'DJS', marketCode: '061' },
	{ categoryCode: 'DJS', marketCode: '338' },
	{ categoryCode: 'DJS', marketCode: '035' },
	{ categoryCode: 'DJS', marketCode: '052' },
	{ categoryCode: 'DJS', marketCode: '006' },
	{ categoryCode: 'DJS', marketCode: '003' },
	{ categoryCode: 'DJS', marketCode: '044' },
	{ categoryCode: 'DJS', marketCode: '051' },
	{ categoryCode: 'DJS', marketCode: '339' },
	{ categoryCode: 'DJS', marketCode: '065' },
	{ categoryCode: 'DJS', marketCode: '053' },
	{ categoryCode: 'DJS', marketCode: '021' },
	{ categoryCode: 'DJS', marketCode: '177' },
	{ categoryCode: 'DJS', marketCode: '180' },
	{ categoryCode: 'DJS', marketCode: '209' },
	{ categoryCode: 'DJS', marketCode: '041' },
	{ categoryCode: 'DJS', marketCode: '017' },
	{ categoryCode: 'FLO', marketCode: '004' },
	{ categoryCode: 'PHB', marketCode: '041' },
	{ categoryCode: 'REC', marketCode: '019' },
	{ categoryCode: 'REC', marketCode: '191' },
	{ categoryCode: 'REC', marketCode: '030' },
	{ categoryCode: 'REC', marketCode: '203' },
	{ categoryCode: 'REC', marketCode: '061' },
	{ categoryCode: 'REC', marketCode: '234' },
	{ categoryCode: 'REC', marketCode: '338' },
	{ categoryCode: 'REC', marketCode: '045' },
	{ categoryCode: 'REC', marketCode: '179' },
	{ categoryCode: 'REC', marketCode: '202' },
	{ categoryCode: 'REC', marketCode: '006' },
	{ categoryCode: 'REC', marketCode: '020' },
	{ categoryCode: 'REC', marketCode: '044' },
	{ categoryCode: 'REC', marketCode: '186' },
	{ categoryCode: 'REC', marketCode: '051' },
	{ categoryCode: 'REC', marketCode: '195' },
	{ categoryCode: 'REC', marketCode: '183' },
	{ categoryCode: 'REC', marketCode: '198' },
	{ categoryCode: 'REC', marketCode: '109' },
	{ categoryCode: 'REC', marketCode: '339' },
	{ categoryCode: 'REC', marketCode: '005' },
	{ categoryCode: 'REC', marketCode: '348' },
	{ categoryCode: 'REC', marketCode: '031' },
	{ categoryCode: 'REC', marketCode: '040' },
	{ categoryCode: 'REC', marketCode: '182' },
	{ categoryCode: 'REC', marketCode: '084' },
	{ categoryCode: 'REC', marketCode: '091' },
	{ categoryCode: 'REC', marketCode: '065' },
	{ categoryCode: 'REC', marketCode: '175' },
	{ categoryCode: 'REC', marketCode: '004' },
	{ categoryCode: 'REC', marketCode: '231' },
	{ categoryCode: 'REC', marketCode: '190' },
	{ categoryCode: 'REC', marketCode: '029' },
	{ categoryCode: 'REC', marketCode: '073' },
	{ categoryCode: 'REC', marketCode: '042' },
	{ categoryCode: 'REC', marketCode: '053' },
	{ categoryCode: 'REC', marketCode: '291' },
	{ categoryCode: 'REC', marketCode: '021' },
	{ categoryCode: 'REC', marketCode: '177' },
	{ categoryCode: 'REC', marketCode: '117' },
	{ categoryCode: 'REC', marketCode: '212' },
	{ categoryCode: 'REC', marketCode: '192' },
	{ categoryCode: 'REC', marketCode: '180' },
	{ categoryCode: 'REC', marketCode: '050' },
	{ categoryCode: 'REC', marketCode: '272' },
	{ categoryCode: 'REC', marketCode: '209' },
	{ categoryCode: 'REC', marketCode: '041' },
	{ categoryCode: 'REC', marketCode: '033' },
	{ categoryCode: 'REC', marketCode: '323' },
	{ categoryCode: 'REC', marketCode: '208' },
	{ categoryCode: 'REC', marketCode: '017' },
	{ categoryCode: 'REC', marketCode: '074' },
	{ categoryCode: 'VID', marketCode: '019' },
	{ categoryCode: 'VID', marketCode: '191' },
	{ categoryCode: 'VID', marketCode: '061' },
	{ categoryCode: 'VID', marketCode: '045' },
	{ categoryCode: 'VID', marketCode: '035' },
	{ categoryCode: 'VID', marketCode: '052' },
	{ categoryCode: 'VID', marketCode: '006' },
	{ categoryCode: 'VID', marketCode: '020' },
	{ categoryCode: 'VID', marketCode: '084' },
	{ categoryCode: 'VID', marketCode: '004' },
	{ categoryCode: 'VID', marketCode: '029' },
	{ categoryCode: 'VID', marketCode: '073' },
	{ categoryCode: 'VID', marketCode: '042' },
	{ categoryCode: 'VID', marketCode: '053' },
	{ categoryCode: 'VID', marketCode: '021' },
	{ categoryCode: 'VID', marketCode: '185' },
	{ categoryCode: 'VID', marketCode: '050' },
	{ categoryCode: 'VID', marketCode: '012' },
	{ categoryCode: 'BDS', marketCode: '061' },
	{ categoryCode: 'BDS', marketCode: '006' },
	{ categoryCode: 'BDS', marketCode: '182' },
	{ categoryCode: 'BDS', marketCode: '084' },
	{ categoryCode: 'BDS', marketCode: '091' },
	{ categoryCode: 'BDS', marketCode: '190' },
	{ categoryCode: 'BDS', marketCode: '029' },
	{ categoryCode: 'BDS', marketCode: '073' },
	{ categoryCode: 'WPH', marketCode: '191' },
	{ categoryCode: 'WPH', marketCode: '030' },
	{ categoryCode: 'WPH', marketCode: '061' },
	{ categoryCode: 'WPH', marketCode: '188' },
	{ categoryCode: 'WPH', marketCode: '045' },
	{ categoryCode: 'WPH', marketCode: '179' },
	{ categoryCode: 'WPH', marketCode: '202' },
	{ categoryCode: 'WPH', marketCode: '006' },
	{ categoryCode: 'WPH', marketCode: '020' },
	{ categoryCode: 'WPH', marketCode: '044' },
	{ categoryCode: 'WPH', marketCode: '051' },
	{ categoryCode: 'WPH', marketCode: '183' },
	{ categoryCode: 'WPH', marketCode: '319' },
	{ categoryCode: 'WPH', marketCode: '339' },
	{ categoryCode: 'WPH', marketCode: '182' },
	{ categoryCode: 'WPH', marketCode: '084' },
	{ categoryCode: 'WPH', marketCode: '065' },
	{ categoryCode: 'WPH', marketCode: '175' },
	{ categoryCode: 'WPH', marketCode: '004' },
	{ categoryCode: 'WPH', marketCode: '321' },
	{ categoryCode: 'WPH', marketCode: '190' },
	{ categoryCode: 'WPH', marketCode: '029' },
	{ categoryCode: 'WPH', marketCode: '073' },
	{ categoryCode: 'WPH', marketCode: '042' },
	{ categoryCode: 'WPH', marketCode: '021' },
	{ categoryCode: 'WPH', marketCode: '177' },
	{ categoryCode: 'WPH', marketCode: '185' },
	{ categoryCode: 'WPH', marketCode: '107' },
	{ categoryCode: 'WPH', marketCode: '214' },
	{ categoryCode: 'WPH', marketCode: '180' },
	{ categoryCode: 'WPH', marketCode: '050' },
	{ categoryCode: 'WPH', marketCode: '012' },
	{ categoryCode: 'WPH', marketCode: '010' },
	{ categoryCode: 'WPH', marketCode: '209' },
	{ categoryCode: 'WPH', marketCode: '041' },
	{ categoryCode: 'WPH', marketCode: '017' },
	{ categoryCode: 'PLN', marketCode: '019' },
	{ categoryCode: 'PLN', marketCode: '191' },
	{ categoryCode: 'PLN', marketCode: '188' },
	{ categoryCode: 'PLN', marketCode: '335' },
	{ categoryCode: 'PLN', marketCode: '006' },
	{ categoryCode: 'PLN', marketCode: '020' },
	{ categoryCode: 'PLN', marketCode: '198' },
	{ categoryCode: 'PLN', marketCode: '004' },
	{ categoryCode: 'PLN', marketCode: '021' },
	{ categoryCode: 'PLN', marketCode: '033' },
	{ categoryCode: 'PLN', marketCode: '017' },
];

export default spotlightExperimentMarkets;
