import cx from 'classnames';
import { History } from 'history';
import { Track } from 'types/analytics';
import AnalyticsConstants from '../../../../../../constants/analytics';
import { buildFiltersForTracking, buildFiltersParams } from '../../../utils';
import Styles from '../styles.scss';

const basePath = (pathname: string) => {
	if (!pathname) {
		return pathname;
	}

	const pathFragments = pathname.split('/');
	return pathFragments.splice(0, 3).join('/');
};

const addCategoryFilter = (
	filter: Search.AppliedFilterPillProps,
	appliedCategoryFilters: Filters.AppliedFilters,
) => {
	if (appliedCategoryFilters[filter.categorySlug]) {
		appliedCategoryFilters[filter.categorySlug].push(filter);
	} else {
		// eslint-disable-next-line no-param-reassign
		appliedCategoryFilters[filter.categorySlug] = [filter];
	}
};

export const buildCategoryFilters = (
	appliedFilters: Search.AppliedFilterPillProps[],
	stagedFilters: Search.StagedFilterPills,
): Filters.CategoryFilters => {
	const appliedCategoryFilters: Filters.CategoryFilters = {};
	appliedFilters.forEach((f) => {
		if (!stagedFilters.remove.find((r) => r.id === f.id)) {
			addCategoryFilter(f, appliedCategoryFilters);
		}
	});

	stagedFilters.add.forEach((f) => {
		addCategoryFilter(f, appliedCategoryFilters);
	});

	return appliedCategoryFilters;
};

export const buildClosedCategoryFilters = (
	stagedFilters: Search.StagedFilterPills,
): Filters.CategoryFilters => {
	const closedCategoryFilters: Filters.CategoryFilters = {};
	stagedFilters.remove.forEach((f) => {
		addCategoryFilter(f, closedCategoryFilters);
	});

	return closedCategoryFilters;
};

interface TrackFilterVendorsParams {
	track: Track;
	categoryCode: Category.CategoryCode;
	categoryFilters: Filters.CategoryFilters;
	categoryName: string;
	location: Redux.Location;
	trackAction: Search.TrackAction;
}

export const trackFilterVendors = ({
	categoryCode,
	categoryFilters,
	categoryName,
	location,
	track,
	trackAction,
}: TrackFilterVendorsParams) =>
	track({
		event: AnalyticsConstants.FILTER_VENDORS,
		properties: {
			action: trackAction,
			location: `${location.city}, ${location.stateCode}`,
			vendorCategoryCode: categoryCode,
			vendorCategory: categoryName,
			...buildFiltersForTracking(categoryFilters),
		},
	});

export interface UpdateHistoryParams {
	isClearFilter: boolean;
	categoryFilters: Filters.CategoryFilters;
	pathname: string;
	search: string;
	sort: string;
	history: History;
	clearFilters?: (categoryFilters: Filters.CategoryFilters) => void;
}

export const updateHistory = ({
	categoryFilters,
	clearFilters,
	history,
	isClearFilter,
	pathname,
	search,
	sort,
}: UpdateHistoryParams) => {
	const searchParams = new URLSearchParams(search);
	const gatekeeper = searchParams.get('gatekeeper');

	const newParams = buildFiltersParams({
		gatekeeper: gatekeeper ? [{ value: gatekeeper }] : [],
		...categoryFilters,
		sort: [{ value: sort }],
	});

	let url = basePath(pathname);
	if (!isClearFilter) {
		url += newParams;
	}

	// ignore meaningless search
	if (url !== pathname + search) {
		history.push(url);
	}

	// this is when location.search is empty,
	// and user wants to clear temporary filter options.
	if (isClearFilter && clearFilters) {
		clearFilters(categoryFilters);
	}
};

export const OUTDOOR_FACETS = {
	'covered-outdoor uncovered-outdoor': 'outdoor',
	'uncovered-outdoor covered-outdoor': 'outdoor',
	'uncovered-outdoor': 'uncovered-outdoor',
	'covered-outdoor': 'covered-outdoor',
};

const outdoorFilterOptions = [
	{
		id: 'a08cd584-a729-4c79-bcfc-93170d296b9d',
		name: 'Outdoor Event Space',
		plural: {
			slug: 'uncovered-outdoors',
			term: 'Outdoor Event Spaces',
		},
		singular: {
			slug: 'uncovered-outdoor',
			term: 'Outdoor Event Space',
		},
	},
	{
		id: '62f601ee-12ab-43d5-bb9d-3dc07103e2ed',
		name: 'Covered Outdoors Space',
		plural: {
			slug: 'covered-outdoors',
			term: 'Covered Outdoors Spaces',
		},
		singular: {
			slug: 'covered-outdoor',
			term: 'Covered Outdoors Space',
		},
	},
];

const settingsFilterOptions: Search.FilterOption[] = [
	{
		id: '188441ef-411d-4e38-89e2-df607f464b7f',
		name: 'Trees',
		plural: {
			slug: 'forests',
			term: 'Forests and Trees',
		},
		singular: {
			slug: 'forest',
			term: 'Forest and Tree',
		},
	},
];

export const settingsFilter: Search.FilterDetails = {
	name: 'Settings',
	slug: 'settings',
	isSingleSelect: false,
	filterOptions: settingsFilterOptions,
};

export const outdoorFilter = {
	filterOptions: outdoorFilterOptions,
	isSingleSelect: false,
	name: 'Outdoor Space',
	slug: 'outdoor_space',
};

export const createClassForPillRow = (
	isSticky: boolean,
	showMapView: boolean,
) => {
	if (showMapView) {
		return cx(Styles.pillMapRow, Styles.stickyPillRow);
	}
	return cx(
		Styles.pillRow,
		isSticky ? Styles.stickyPillRow : Styles.nonStickyPillRow,
	);
};
